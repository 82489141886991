import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";

import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";

import Typography from "@material-ui/core/Typography";
import globalStyles from "./styles";

export default function EntryMarkdown({name}){
    const classes = globalStyles();

    let entry = null;
    //    if(dir == "gezielteInformationen"){
    entry = useStaticQuery(graphql`
        query {
            allFile(filter: {relativeDirectory: {eq: "gezielteInformationen"}}) {
            edges {
              node {
                name
                childMarkdownRemark {
                  html
                }
              }
            }
          }
        }
        `);
    //    }else if(dir == "sicherheiten"){
    //        entry = useStaticQuery(graphql`
    //        query {
    //            allFile(filter: {relativeDirectory: {eq: "sicherheiten"}}) {
    //            edges {
    //              node {
    //                name
    //                childMarkdownRemark {
    //                  html
    //                }
    //              }
    //            }
    //          }
    //        }
    //        `);
    //    }
    let content = null;
    entry.allFile.edges.map((o, ind) => {
        if(o.node.name == name){
            content = (
                <>
                    <ExpansionPanelDetails key={ind}>
                        <Typography
                            className={ classes.entryContainer }
                            component="div"
                        >
                            <div
                                dangerouslySetInnerHTML={{ __html: o.node.childMarkdownRemark.html}}
                            />
                        </Typography>
                    </ExpansionPanelDetails>
                </>
            );
        }
    });
    return <> {content} </>;
}

EntryMarkdown.propTypes = {
    dir: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
};
