import React from "react";

import EntryMarkdown from "../components/EntryMarkdown";
import Footer from "../components/footer";
import InfoBar from "../components/InfoBar";
import Navigation from "../components/Navigation";
import Logo from "../components/Logo";
import InfoTitle from "../components/InfoTitle";

import globalStyles from "../components/styles";

import _ from "lodash-core";

export default function MarkdownSeiten ({location}){
    const classes = globalStyles();


    const content = (
        <>
            <Logo />
            <div className={ classes.infoMD}>
                <InfoTitle type="1" />
                <InfoBar wide="white">
                    <Navigation />
                    <EntryMarkdown name={_.get(location, "state.name") }
                        dir={_.get(location, "state.dir") } />
                </InfoBar>
                <Footer />
            </div>
        </>
    );

    return <> {content} </>;
}
